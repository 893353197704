import { Component, OnInit, ElementRef, AfterViewInit } from '@angular/core';
import { RoomNoService } from '../service/room-no.service';
import { SideBar } from '../shared/component/sidebar/siderbar';
import { KeyCode } from '../shared/const/KeyCode';
import { Router } from '@angular/router';
import { LanguageService } from '../service/language.service';

@Component({
  selector: 'app-remote-contorl',
  templateUrl: './remote-contorl.component.html',
  styleUrls: ['./remote-contorl.component.scss']
})
export class RemoteContorlComponent implements OnInit, AfterViewInit {
  lang: 'jpn' | 'eng';
  appname: string;
  hotelIndex = 0;
  focusIndex = 0;
  constructor(
    public roomService: RoomNoService,
    private el: ElementRef,
    private router: Router,
    private roomNoService: RoomNoService,
    private languageService: LanguageService
  ) {}
  element: HTMLElement;

  sidebar: SideBar<YakkanSideBarTiTleJpn, YakkanSideBarTitleEng>[] = [
    {
      title: 'メニューへ戻る',
      title_jpn: 'メニューへ戻る',
      title_eng: 'Back to menu',
      checked: true,
      key_code: '10',
      focused: true
    }
  ];

  ngAfterViewInit() {
    (<any>document.querySelector('.tab_menu_li')).focus();
  }

  setFocusIndex(index: number) {
    if (
      ['メニューへ戻る', 'Back to menu'].includes(this.sidebar[index].title)
    ) {
      this.router.navigate(['/room', this.roomNoService.room_no]);
    }
    this.focusIndex = index;
    this.sidebar.forEach(d => (d.checked = false));
    this.sidebar[this.focusIndex].checked = true;
    this.hotelIndex = index;
  }
  ngOnInit() {
    this.lang = this.languageService.language;
    // Languageをサービスしちゃう
    if (this.languageService.language === 'eng') {
      //this.imgurl = '/assets/breakfast/eng/breakfast.png';

      this.appname = 'Remote controller';
      this.sidebar = this.sidebar.map(d => {
        d.title = d.title_eng;
        return d;
      });
    } else {
      //this.imgurl = '/assets/breakfast/jpn/breakfast.png';

      this.appname = 'リモコン';
    }
  }
}
declare function require(x: string): any;
type YakkanSideBarTiTleJpn = '宿泊約款' | '利用規約';
type YakkanSideBarTitleEng = 'Regulation' | 'Terms of service';
