<div class="wrapper">
  <app-appli-side-bar [sidebars]="sidebar" (clickEventIndex)="setFocusIndex($event)"></app-appli-side-bar>
  <div class="top_icon"></div>
  <div class="app_name">{{ appname }}</div>
  <div class="remote_control"></div>

  <div class="main_content_wrapper">
    <div class="main_content">
      <img src="/assets/qr.png" style="width: 300px; height:300px;">
      <div *ngIf="lang=='jpn'">
      「ご宿泊のご案内」「宿泊約款」「利用規約」<br>
      QRを読み取っていただくことで、いつでもご確認いただけます。
      </div>
      <div *ngIf="lang=='eng'">
	 "Accommodation Guide," "Terms and Conditions," and "Terms of Use.<br>
	You can check it at any time by scanning the QR.
      </div>
    </div>
  </div>
</div>
