<div class="wrapper">
  <app-appli-side-bar [sidebars]="sidebar" (clickEventIndex)="setFocusIndex($event)"></app-appli-side-bar>
  <div class="top_icon"></div>
  <div class="app_name">{{ appname }}</div>
  <div class="remote_control"></div> 
  <div class="datetime">   {{ now_date_obj.year }}.{{ now_date_obj.month }}.{{ now_date_obj.day }}
      {{ now_date_obj.hhmm }}</div>

  <app-appli-main
    [appList]="appList[appListIndex]"
    (changeFocus)="appliMainChangeFocus($event)"
    (launchAppli)="launchAppli($event)"
    (resetAllFocus)="resetAllFocus()"
    >
  </app-appli-main>

  <div style="width: 1070px;
    z-index: 99999999;
    position: absolute;
    color: #000;
    left: 570px;
    font-size: 60%;
    line-height: 1.5em;
    top: 800px;
    border: solid 1px;
    padding: 1em;">
    <span *ngIf="lang=='jpn'">
    ※各アプリ利用時にはご自身のアカウントが必要な場合があります。<br>
    電源オフ時にTVよりアカウント情報が削除されます。
    </span>
        <span *ngIf="lang=='eng'">
    You may be required to have your own account to use each application.<br>
    The account information will be deleted from the TV when the power is turned off.
    </span>
  </div>
</div>
