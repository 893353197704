import { Component, OnInit, OnDestroy } from '@angular/core';
import { SideBar } from '../shared/component/sidebar/siderbar';
import { RoomNoService } from '../service/room-no.service';
import { Router } from '@angular/router';
import { BraviaApiService } from '../service/bravia-api.service';
import { Observable, Subscription } from 'rxjs';
import {
  getNowDateObject,
  getStreamNowDate
} from '../shared/tools/DateNowTime';
import { nowDate } from '../index/nowDate';
import { LanguageService } from '../service/language.service';

@Component({
  selector: 'app-nearby-info',
  templateUrl: './nearby-info.component.html',
  styleUrls: ['./nearby-info.component.scss']
})
export class NearbyInfoComponent implements OnInit, OnDestroy {
  now_date = getStreamNowDate();
  sub: Subscription[] = [];
  now_date_obj: nowDate = getNowDateObject(
    new Date(),
    this.languageService.language
  );

  focusIndex = 0;
  rentalIndex = 0;
  sidebar: SideBar<NearbylInfoSideBarTiTleJpn, NearbylInfoSideBarTiTleEng>[] = [
    {
      title: 'コンビニエンスストア',
      title_jpn: 'コンビニエンスストア',
      title_eng: 'Convenience Store',
      key_code: '1',
      checked: true,
      focused: false
    },
    {
      title: '最寄り駅',
      title_jpn: '最寄り駅',
      title_eng: 'Station',
      key_code: '2',
      checked: false,
      focused: false
    },
    {
      title: 'メニューへ戻る',
      title_jpn: 'メニューへ戻る',
      title_eng: 'Back to menu',
      key_code: '92',
      checked: false,
      focused: false
    }
  ];

  constructor(
    private roomNoService: RoomNoService,
    private router: Router,
    private braviaService: BraviaApiService,
    private languageService: LanguageService
  ) {}

  ngOnInit() {
    // 現在時刻の表示
    this.sub.push(
      this.now_date.subscribe(d => {
        this.now_date_obj = getNowDateObject(d, this.languageService.language);
      })
    );
  }
  ngOnDestroy() {
    this.sub.forEach(d => d.unsubscribe());
  }
  setFocusIndex(index: number) {
    if (this.sidebar[index].title === 'メニューへ戻る') {
      this.router.navigate(['/room', this.roomNoService.room_no]);
    }
    this.focusIndex = index;
    this.sidebar.forEach(d => (d.checked = false));
    this.sidebar[this.focusIndex].checked = true;
    this.rentalIndex = index;
  }
}
type NearbylInfoSideBarTiTleJpn = 'コンビニエンスストア' | '最寄り駅';
type NearbylInfoSideBarTiTleEng = 'Convenience Store' | 'Station';
