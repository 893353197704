import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SideBar } from '../sidebar/siderbar';

@Component({
  selector: 'app-appli-side-bar',
  templateUrl: './appli-side-bar.component.html',
  styleUrls: ['./appli-side-bar.component.scss']
})
export class AppliSideBarComponent implements OnInit {
  @Input() sidebars: SideBar<string, string>[];
  @Input() focusIndex: number;
  @Output() clickEventIndex = new EventEmitter<number>();
  @Output() allFocusReset = new EventEmitter();
  @Output() setFocus = new EventEmitter<number>();
  constructor() {}
  ngOnInit() {}

  click(index: number) {
    this.clickEventIndex.emit(index);
  }
  focus(i: number) {
    this.sidebars.forEach(d => (d.focused = false));
    this.sidebars[i].focused = true;
  }
  blur() {
    this.allFocusReset.emit();
  }
}
