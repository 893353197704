<div class="wrapper">
  <app-appli-side-bar [sidebars]="sidebar" (clickEventIndex)="setFocusIndex($event)"></app-appli-side-bar>
  <div class="top_icon"></div>
  <div class="app_name">{{ appname }}</div>
  <div class="remote_control"></div>
  <div class="datetime">
    {{ now_date_obj.year }}.{{ now_date_obj.month }}.{{ now_date_obj.day }}
    {{ now_date_obj.hhmm }}
  </div>
  <div class="main_content_wrapper">
    <div class="main_content">
      <div *ngIf="lang == 'jpn'" >
	<h1>一日の活力を朝食で</h1>
	地産地消に取り組み、京都の食材を使ったご朝食をご用意いたします。
	<dl class="breakfast-list">
	  <dt>
	    ご朝食会場</dt>
	  <dd>1階<dd>
	    <dt>お時間</dt>
	  <dd>7：00　～　9：00</dd>
	  <dt>ご料金</dt>
	  <dd>小学生以上 2,000円<br>
	    未就学児 無料</dd>
	</dl>
	<div style="text-align:center;display:block;"><small>
	    ※レストランへはご朝食券をお持ちくださいませ。
	  </small>
	</div>
      </div>


      <div *ngIf="lang == 'eng'" >
	<h1>Start the day with breakfast.</h1>
	We are committed to local production for local consumption <br >and will prepare a breakfast using Kyoto ingredients.
	<dl class="breakfast-list">
	  <dt>
	    Breakfast room</dt>
	  <dd>First floor<dd>
	    <dt>Time</dt>
	  <dd>7：00　～　9：00</dd>
	  <dt>Charges</dt>
	  <dd>Elementary school students and above 2,000 JPY<br>
	    preschool child free</dd>
	</dl>
	<div style="text-align:center;display:block;"><small>
	    Please bring your breakfast ticket to the restaurant.
	  </small>
	</div>
      </div>

    </div>
  </div>
</div>
