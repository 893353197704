import {
  Component,
  OnInit,
  ElementRef,
  AfterViewInit,
  OnDestroy
} from '@angular/core';
import { RoomNoService } from '../service/room-no.service';
import { LanguageService } from '../service/language.service';
import { SideBar } from '../shared/component/sidebar/siderbar';
import { Subscription } from 'rxjs';

import {
  getNowDateObject,
  getStreamNowDate
} from '../shared/tools/DateNowTime';
import { nowDate } from '../index/nowDate';
import { Router } from '@angular/router';

@Component({
  selector: 'app-breakfast',
  templateUrl: './breakfast.component.html',
  styleUrls: ['./breakfast.component.scss']
})
export class BreakfastComponent implements OnInit, OnDestroy, AfterViewInit {
  lang: 'jpn' | 'eng';
  now_date = getStreamNowDate();
  now_date_obj: nowDate = getNowDateObject(
    new Date(),
    this.languageService.language
  );
  sub: Subscription[] = [];
  imgurl: string;
  appname: string;
  focusIndex = 0;
  hotelIndex = 0;
  sidebar: SideBar<BreakfastSideBarTiTleJpn, BreakfastSideBarTiTleEng>[] = [
    {
      title: 'メニューへ戻る',
      title_jpn: 'メニューへ戻る',
      title_eng: 'Back to menu',
      key_code: '92',
      checked: true,
      focused: true
    }
  ];
  constructor(
    private roomNoService: RoomNoService,
    private router: Router,
    private languageService: LanguageService,
    private el: ElementRef
  ) {}
  element: HTMLElement;

  ngAfterViewInit() {
    (<any>document.querySelector('.tab_menu_li')).focus();
  }

  ngOnInit() {
    this.lang = this.languageService.language;
    // Languageをサービスしちゃう
    if (this.languageService.language === 'eng') {
      this.imgurl = '/assets/breakfast/eng/breakfast.png';

      this.appname = 'Breakfast';
      this.sidebar = this.sidebar.map(d => {
        d.title = d.title_eng;
        return d;
      });
    } else {
      this.imgurl = '/assets/breakfast/jpn/breakfast.png';

      this.appname = 'ご朝食';
    }
    // 現在時刻の表示
    this.sub.push(
      this.now_date.subscribe(d => {
        this.now_date_obj = getNowDateObject(d, this.languageService.language);
      })
    );
  }
  ngOnDestroy() {
    this.sub.forEach(d => d.unsubscribe());
  }

  setFocusIndex(index: number) {
    if (
      ['メニューへ戻る', 'Back to menu'].includes(this.sidebar[index].title)
    ) {
      this.router.navigate(['/room', this.roomNoService.room_no]);
    }
    this.focusIndex = index;
    this.sidebar.forEach(d => (d.checked = false));
    this.sidebar[this.focusIndex].checked = true;
    this.hotelIndex = index;
  }
}
type BreakfastSideBarTiTleJpn = '朝食について';

type BreakfastSideBarTiTleEng = 'Breakfast';
