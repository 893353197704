import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ViewChildren,
  QueryList
} from '@angular/core';
import { AppliMain } from '../../../appli-list-main/appli-main';

@Component({
  selector: 'app-appli-main',
  templateUrl: './appli-main.component.html',
  styleUrls: ['./appli-main.component.scss']
})
export class AppliMainComponent implements OnInit, AfterViewInit {
  @Input() appList: AppliMain[];
  @Output() changeFocus = new EventEmitter<number>();
  @Output() launchAppli = new EventEmitter<number>();
  @Output() resetAllFocus = new EventEmitter<void>();

  constructor() {}

  @ViewChildren('AppIcons')
  AppIcons: QueryList<any>;

  ngOnInit() {}

  ngAfterViewInit() {
    this.AppIcons.changes.subscribe(t => {
      // 最下部にスクロール
      //this.AppIcons.nativeElement.focus();
      (<any>document.querySelector('.icon_item')).focus();
    });
  }

  focus(i: number) {
    this.changeFocus.emit(i);
    this.appList.forEach(d => (d.focused = false));
    this.appList[i].focused = true;
  }

  click(i: number) {
    this.launchAppli.emit(i);
  }

  blur() {
    this.resetAllFocus.emit();
  }
}
