<div class="wrapper">
  <app-appli-side-bar [sidebars]="sidebar" (clickEventIndex)="setFocusIndex($event)"></app-appli-side-bar>
  <div class="top_icon"></div>
  <div class="app_name">{{ appname }}</div>
  <div class="remote_control"></div>

  <div class="main_content_wrapper">
    <div class="main_content">
      <img src="/assets/remote-control/remote.png" style="width: 100%;" *ngIf="lang=='jpn'">
      <img src="/assets/remote-control/remote_eng.png" style="width: 100%;" *ngIf="lang=='eng'">
    </div>
  </div>
</div>
