import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-right',
  templateUrl: './right.component.html',
  styleUrls: ['./right.component.scss']
})
export class RightComponent implements OnInit {
  displayFlag = false;
  constructor() {}

  ngOnInit() {
    //setTimeout(() => {
    //  this.displayFlag = true;
    //}, 600000);
  }
  click() {
    const e = document.querySelector('object');
    e.style.display = e.style.display === 'none' ? '' : 'none';
  }
}
