<div id="layout"></div>

<div class="header" [style.z-index]="999">
    <div class="title"></div>
    <div class="wifi-info">
        <div class="qr_code">
            <qr-code [value]="qr" [size]="95" [foreground]="'brown'"></qr-code>
        </div>

        <!-- wifi area-->

        <div class="wifi-title">
            <dl>
                <dt>SSID</dt>
                <dd>{{ ssid }}</dd>
                <dt>KEY</dt>
                <dd>
                    {{ password }}
                </dd>
            </dl>
        </div>
    </div>
    <div class="front-info" [ngClass]="{'front-info_eng': isEng}">
        <div class="front-tel">55</div>
    </div>
    <div class="weather" [ngClass]="{'weather_eng': isEng}">
        <div class="weather_today">
            <canvas width="73" height="70" id="today"></canvas>
            <span class="rainy">{{ today_rainy }}</span>
        </div>
        <div class="today_temp">
            <p class="temp">
                {{ today_temp }}
            </p>
        </div>
        <div class="weather_tomorrow">
            <canvas width="73" height="70" id="tomorrow"></canvas>
            <span class="rainy">{{ tommorow_rainy }}</span>
        </div>
        <div class="tommorow_temp">
            <p class="temp">
                {{ tommorow_temp }}
            </p>
        </div>

    </div>
    <div class="clock">
        <!-- clock area-->
        <div class="time">
            {{ now_date_obj.hhmm }}
        </div>
        <div class="date">
            <div class="year">{{ now_date_obj.year }}.</div>
            <div class="month-date">{{ now_date_obj.month }}. {{ now_date_obj.day }}</div>
            <div class="week">({{ now_date_obj.day_of_week }})</div>
        </div>
    </div>
</div>




<div class="main" [style.z-index]="-1">
    <div id="particles-js"></div>

    <div class="left">
        <ng-container *ngFor="let item of icons; let i = index">
            <div class="icon_item">
                <div (click)="aClick(i)" (blur)="blur(i)" (focus)="changeIcon(i)" class="fuga">
                    <img [hidden]="item.focused" [src]="item.icon" class="icon_img" />
                    <img [hidden]="!item.focused" [src]="item.icon" class="icon_img_focus" />
                </div>
            </div>
        </ng-container>
    </div>
    <app-right></app-right>
</div>

<div class="footer">
    <div class="footer-bar"></div>
    <div class="room_info" [ngClass]="{'room_info_eng': isEng}">
        <div class="room_number">{{ room_no }}</div>
        <div [ngClass]="{'erase_capa_eng': isEng, 'erase_capa': !isEng}"></div>
    </div>
</div>

<img src="/assets/top-image/license.png" class="license">