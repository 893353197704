<div class="wrapper">
  <app-appli-side-bar
    [sidebars]="sidebar"
    (clickEventIndex)="setFocusIndex($event)"
    ></app-appli-side-bar>
  <div class="top_icon"></div>
  <div class="app_name">{{ appname }}</div>
  <div class="remote_control"></div>
  <div class="datetime">
    {{ now_date_obj.year }}.{{ now_date_obj.month }}.{{ now_date_obj.day }}
    {{ now_date_obj.hhmm }}
  </div>
  <div class="main_content_wrapper">
    <div class="main_content">

      <div *ngIf="rentalIndex == 0">
        <div class="ja" *ngIf="lang == 'jpn'">
          <h1>貸出品</h1>
	  <ul class="rental-list">
	    <li>・充電ケーブル
	    <li>・栓抜き
	    <li>・アイロンセット
	    <li>・ズボンプレッサー
	    <li>・爪切り
	    <li>・体温計
	    <li>・車いす
	    <li>・毛布
	  </ul>
	  ※在庫に限りがございます。予めご了承くださいませ。

          <h1>モーニングコール</h1>
          <p>客室電話より「*68」をダイヤルして下さい。</p>
          <h1>チェックアウト</h1>      
          <p>チェックアウトのお時間は11時まででございます。<br>
            <br>
            ご延長は最大12時まで可能です。（1室あたり1,000円）<br>
            ご延長をご希望の場合は、フロント内線55番までご用命くださいませ。<br>
            <small>※12時以降のご延長には追加一泊のご宿泊代金を頂戴いたします。</small>          
          </p>  
        </div>

	<div class="ja" *ngIf="lang == 'eng'">
          <h1>Items for rent</h1>
	  <ul class="rental-list">
	    <li>・Charging cable
	    <li>・Bottle opener
	    <li>・Ironing set
	    <li>・Trouser press
	    <li>・Nail clippers
	    <li>・Thermometer
	    <li>・Wheelchair
	    <li>・Blanket
	  </ul>
	  Limited stock available.

          <h1>Wakeup Call</h1>
          <p>Dial *68 with phone</p>
          <h1>Checkout time</h1>      
          <p>Check-out time is by 11:00.<br>
            <br>
	    Extensions are available up to 12:00. (1,000 yen per room).<br>
	    If you would like to extend your stay, please call the front desk at extension 55.<br>
	    If you extend your stay after 12:00, you will be charged for an additional night.
          </p>  
	</div>
      </div>

    </div>
  </div>
</div>
