<div class="wrapper">
  <app-appli-side-bar
    [sidebars]="sidebar"
    (clickEventIndex)="setFocusIndex($event)"
  ></app-appli-side-bar>
  <div class="top_icon"></div>
  <div class="app_name">{{ appname }}</div>
  <div class="remote_control"></div>
  <div class="datetime">
    {{ now_date_obj.year }}.{{ now_date_obj.month }}.{{ now_date_obj.day }}
    {{ now_date_obj.hhmm }}
  </div>
  <div class="main_content_wrapper">
    <div class="main_content">
      <!--自動販売機について-->
      <div *ngIf="hotelIndex == 0">
        <div class="ja" *ngIf="lang == 'jpn'">

	  <dl>
	    <dt>1階</dt>
	    <dd>コインランドリー・朝食会場</dd>
	    <dt>2階</dt>
	    <dd>自販機（清涼飲料水・アルコール）・製氷機・電子レンジ</dd>
	  </dl>
	  

        </div>

        <div class="en" *ngIf="lang == 'eng'">
	  <dl>
	    <dt>First floor</dt>
	    <dd>Laundromat and breakfast room</dd>
	    <dt>2nd floor</dt>
	    <dd>Vending machines (soft drinks and alcohol), ice machines, microwave ovens</dd>
	  </dl>
        </div>
      </div>

    </div>
  </div>
</div>
