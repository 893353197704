<div class="wrapper">
    <app-appli-side-bar
      [sidebars]="sidebar"
      (clickEventIndex)="setFocusIndex($event)"
    ></app-appli-side-bar>
    <div class="top_icon"></div>
    <div class="app_name">ホテル周辺情報</div>
    <div class="remote_control"></div>
    <div class="datetime">
      {{ now_date_obj.year }}.{{ now_date_obj.month }}.{{ now_date_obj.day }}
      {{ now_date_obj.hhmm }}
    </div>
    <div class="main_content_wrapper">
      <div class="main_content">
        <!--レンタル品一覧-->
        <div *ngIf="rentalIndex == 0">
          <div class="ja">
            コンビニについて
          </div>
        </div>
  
        <!--客室清掃について-->
        <div *ngIf="rentalIndex == 1">
          <div class="ja flex_content">
            最寄り駅について
          </div>
        </div>
  
        <!--bluetoothについて-->
        <div *ngIf="rentalIndex == 2">
          <div class="ja">
            <ul>
              <li>
                Bluetoothスピーカーと機器のペアリング(接続)<br />
                Bluetoothボタンを2秒以上押し続ける。<br />
                その後、接続したい機器のBluetoothの設定をオンにする。<br />
              </li>
              <li>
                音量にご注意ください。<br />
                接続する機器の音量設定を下げてから再生してください。<br />
                予想外の大音量が流れることがあります。<br />
              </li>
            </ul>
          </div>
        </div>
  
        <!--客室マッサージ-->
        <div *ngIf="rentalIndex == 3">
          <div class="ja">
            <img src="/assets/stay-info/inroom.jpg" heigh="800" />
          </div>
        </div>
      </div>
    </div>
  </div>
  